body.modal-open {
    overflow: hidden;
  }
  
  .modal-backdrop {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
  }
  .admincard{
  width: 25%;
  margin: 3%;
   background-color: #c76232;
   color: white;
  }
  .cardcont{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
body{
  overflow-x: hidden;
}
.serchcont{
  display: flex;
  flex-direction: row;
  margin-left: 50%;
}
@media (max-width: 576px) {
  /* Styles for extra small devices */
  .cardcont{
    flex-direction:  column;
  }
  .admincard{
    width: 90%;
     
    }
    .serchcont{
      display: flex;
      flex-direction: column;
      margin-left: 0%;
      width: 20%;
    }
}
@media (min-width: 576px) and (max-width: 768px) {
  /* Styles for small devices */
  .serchcont{
    display: flex;
    flex-direction:  row;
    margin-left: 0%;
     
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  /* Styles for medium devices */
  .serchcont{
    display: flex;
    flex-direction:  row;
    margin-left: 0%;
     
  }
}



  
   
.resource-card {
    position: relative;
    overflow: hidden;
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    border-radius: 8px;
    transition: transform 0.3s;
  }
  
  .resource-card:hover {
    transform: scale(1.05);
  }
  
  .resource-image {
    width: 100%;
    height: 250px;
    border-radius: 8px 8px 0 0;
  }
  
  .resource-hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s;
    flex-direction: column;
    padding: 20px;
    text-align: center;
  }
  
  .resource-card:hover .resource-hover {
    opacity: 1;
  }
  
  .resource-link {
    text-decoration: none;
    color: white;
  }
  
  .resource-hover h5 {
    margin-bottom: 10px;
  }
  
  .resource-hover p {
    margin-bottom: 20px;
  }
  
  .resource-hover button {
    background-color: #007bff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .resource-hover button:hover {
    background-color: #0056b3;
  }
  .resbanner{
    background-image: url( https://s26162.pcdn.co/wp-content/uploads/sites/2/2023/01/Books.jpg);
    height: 40vh;
    background-size: cover;
    background-position-y:  center;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .resbanner h1{
    font-size: 80px;
  }
  .c1{
    margin-top: 4%;
    margin-bottom: 3%;
  }

  @media (max-width: 576px) {
    /* Styles for extra small devices */
    .resbanner h1{
      font-size: 60px;
    }
  }
  @media (min-width: 768px) and (max-width: 992px) {
    /* Styles for medium devices */
    .c1{
      margin-left: 0%;
      margin-right: 0%;
       max-width: 850px !important;
    }
  }
  
  
  
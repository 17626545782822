/* Contact.css */

.contact-background {
    background-image: url('/public/img/contactusbg.jpg');
    background-size: cover;
    background-position: left;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .form-container {
    max-width: 600px;
    width: 100%;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    margin-top: 3%;

  }

  .check{
    text-align: left;
  }
  
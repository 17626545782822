body {
  font-family: Arial, sans-serif;
  background: #f4f4f9;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}

.form-container {
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.register-form h2 {
  text-align: center;
  margin-bottom: 1rem;
  color: #333;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #555;
}

.form-group input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
}

.form-group input:focus {
  border-color: #007bff;
  outline: none;
}

.submit-button {
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 5px;
  background: #007bff;
  color: white;
  font-size: 1rem;
  cursor: pointer;
}

.submit-button:hover {
  background: #0056b3;
}
.google-login-btn{
  margin-top: 0  !important;
  width: 70%;
}
.sendOtp{
  width: 100%;
}
.startcont{
  width: 100%;
}
.verify{
  flex-direction: column;
  width: 100%;
}
.verbtn{
  width: 100%;
  margin-top: 2%;
}
.regbtn{
  width: 100%;
}
 
.form-outline {
  position:  absolute;
  /* width: 50%   !important; */
}

  .formcont{
    width: 95%;
  }
 
 

.about-container{
    overflow-x: hidden;
}
.main{
 background-image: url(/public/img/aboutusHeader.webp);
 height: 40vh;
 color: white;
 background-size: cover;
 display: flex;
 justify-content: center;
 flex-direction: column;
}
.main h1{
   font-size: 80px;
}
/* about section start */
.about-section{
    height: 35vh;
    display: flex;
 flex-direction: row;
 background-color: white;
margin: 2%;
}
.about1{
    display: flex;
 flex-direction:  column;
 margin: 2%;
 justify-content:center;
 text-align: right;
 width: 50%;
}
.about2{ 
    margin: 2%;
    width: 50%;
}
.about2 img{
    width: 100%;
    height: 100%;
}
/* about section end */
/* vision section start */
.vision-section{
    height: 45vh;
    display: flex;
 flex-direction: row;
 background-color: white;
margin: 2%;
}
.vision1{
    margin: 2%;
    width: 50%;
}
.vision1 img{
    width: 100%;
    height: 100%;
}
.vision2{
    display: flex;
    flex-direction:  column;
    margin: 2%;
    justify-content:center;
    text-align: left;
    width: 50%;
}
/* vision section end */
/*  mission section start*/
.mission-section{
    height: 50vh;
    display: flex;
 flex-direction: row;
 background-color: white;
margin: 2%;
}
.mission1{
    display: flex;
    flex-direction:  column;
    margin: 2%;
    justify-content:center;
    text-align: right;
    width: 50%;
}
.mission2{
    margin: 2%;
    width: 50%;
}
.mission2 img{
    width: 100%;
    height: 100%;
}
/* mission section end  */
/* offer section start */
.offer-section{
    height: 50vh;
    display: flex;
 flex-direction: row;
 background-color: white;
margin: 2%;
}
.offer1{
    margin: 2%;
    width: 50%;
}
.offer2{
    display: flex;
    flex-direction:  column;
    margin: 2%;
    justify-content:center;
    text-align:  left;
    width: 50%;
}
.offer1 img{
    width: 100%;
    height: 100%;
}
/* offer section end */
/* team section start */
.team-section{
    padding: 2%;
    background-color: #6f3226;
    color: white;
}
/* team section end */
/* why section start */
.why-choose-us-section{
    height: 40vh;
    display: flex;
 flex-direction: row;
 background-color: white;
margin: 2%; 
}
.why1{
    display: flex;
    flex-direction:  column;
    margin: 2%;
    justify-content:center;
    text-align:  left;
    width: 50%;
}
.why2 img{
    width: 100%;
    height: 100%;  
}
.why2{
    margin: 2%;
    width: 50%;
}
/* why section end */
/* thank section start */
.thank-you-section{
    padding: 2%;
    background-color: #6f3226;
    color: white;
}
/* thank section end */


/* media query  */
@media (min-width: 768px) and (max-width: 992px) {
    /* Styles for medium devices */
    .about-section{
        height: 25vh !important;
    }
    .about1{
        width: 40% !important;
    }
    .about2{
        width: 60% !important;
    }
    .vision-section{
        height: 30vh;
    }
    .vision1{
        width: 60%;
    }
    .vision2{
        width: 40%;
    }
    .mission-section{
        height: 30vh;
    }
    .mission1{
        width: 40%;
    }
    .mission2{
        width: 60%;
    }
    .offer-section{
        height: 60vh;
    }
    .offer1{
        width: 60%;
    }
    .offer2{
        width:40%;
    }
    .why-choose-us-section{
        height: 30vh;
    }
    .why1{
        width: 40%;
    }
    .why2{
        width: 60%;
    }
  }
  @media (max-width: 576px) {
    /* Styles for extra small devices */
    .main h1{
        font-size: 60px;
    }
    .about-section{
        flex-direction: column-reverse;
        height: 45vh;
    }
    .about1{
        width: 95%;
    }
    .about2{
        width: 95%;
    }
    .vision-section{
        flex-direction: column;
        height: 60vh;
    }
    .vision1{
        width: 95%;
    }
    .vision2{
        width: 95%;
    }
    .mission-section{
        flex-direction: column-reverse;
        height: 65vh ;
    }
    .mission1{
        width: 95%;
    }
    .mission2{
        width: 95%;
    }
    .offer-section{
        flex-direction: column;
        height: 105vh;
    }
    .offer1{
        width: 95%;
    }
    .offer2{
        width: 95%;
    }
    .why-choose-us-section{
        flex-direction: column-reverse;
        height: 55vh;
    }
    .why1{
        width: 95%;
    }
    .why2{
        width: 95%;
    }
  }
  
  
.dashboard-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.dashboard-card {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.dashboard-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.dashboard-card h2 {
  margin-bottom: 20px;
}

.dashboard-card form {
  display: flex;
  flex-direction: column;
}

.dashboard-card input {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.dashboard-card button {
  padding: 10px;
  border: none;
  background-color: #4285f4;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.dashboard-card button:hover {
  background-color: #357ae8;
}

.gradient-custom {
  /* fallback for old browsers */
  background: #d56a34;
}
.uimg {
  border-radius: 50%;
}
.vh {
   background-image: url(dashbg.png);
   background-size: cover;
   background-position: bottom;
}
